"use client";
import { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



const ClientSwiperSection: FC<{}> = () => {

	const CLIENT_LOGOS = [
		{
			imgLink: "https://assets.medentic.app/assets/images/client/01.svg",
			label: "l1",
		},
		{
			imgLink: "https://assets.medentic.app/assets/images/client/02.svg",
			label: "l1",
		},
		{
			imgLink: "https://assets.medentic.app/assets/images/client/03.svg",
			label: "l1",
		},
		{
			imgLink: "https://assets.medentic.app/assets/images/client/04.svg",
			label: "l1",
		},
		{
			imgLink: "https://assets.medentic.app/assets/images/client/05.svg",
			label: "l1",
		},
		{
			imgLink: "https://assets.medentic.app/assets/images/client/06.svg",
			label: "l1",
		},
		{
			imgLink: "https://assets.medentic.app/assets/images/client/07.svg",
			label: "l1",
		},
		{
			imgLink: "https://assets.medentic.app/assets/images/client/08.svg",
			label: "l1",
		},
	];

	return (
		<Swiper
			spaceBetween={30}
			slidesPerView={2}
			breakpoints={{
				576: { slidesPerView: 2 },
				768: { slidesPerView: 3 },
				992: { slidesPerView: 4 },
				1200: { slidesPerView: 6 },
			}}
			loop={true}
		>
			{CLIENT_LOGOS.map((logo, index) => (
				<SwiperSlide className="swiper-slide" key={index}>
					<img src={logo.imgLink} className="grayscale px-sm-4 ps-0" alt={logo.label} />
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default ClientSwiperSection;
