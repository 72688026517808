"use client";

import { CldImage as CldImageDefault, CldImageProps } from "next-cloudinary";

// https://next.cloudinary.dev/cldimage/configuration
// https://cloudinary.com/documentation/resizing_and_cropping#crop
const CldImageClient = (props: CldImageProps) => {
  return <CldImageDefault {...props} />;
};

export default CldImageClient;
